<template>
  <div class="game-grid" ref="gameContainer">
    <Portal to="leaderboardOverlay">
      <LeaderboardModal 
        :gameId="this.$route.params.gameId" 
        :isShown="showModal"
        :gameDetails="leaderboardDetails" 
        @closeModal="onModalClose"/>
    </Portal>
    <PortalTarget name="leaderboardOverlay"/>
    <!-- Mobile Tabs -->
    <div class="first-row-mobile" v-show="isMobile">
      <div class="tabs">
        <a :class="[{active: currentTab == 0},'order-tab tab-item']" @click="currentTab = 0" href="#CTADetailsAnchor">Game Action</a>
        <a :class="[{active: currentTab == 1},'order-tab tab-item']" @click="currentTab = 1" href="#accountInfoAnchor">Account Info</a>
        <a :class="[{active: currentTab == 2},'order-tab tab-item']" @click="currentTab = 2" href="#rankingDetailsAnchor">Ranking</a>
      </div>
      <div class="day-info">
        <div class="progress" id="countdown">
          <Timer
            :timeLimit="gameTimer"
            :currentTimerLeft="currentGameTimer"
            ref="mobileTimer"
          />
        </div>
        <div class="current-day-information">
          <div class="info">
            <p>DAY</p><span>{{this.gameSession.current_duration}}</span>
          </div>
          <div class="info">
            <p>PRICE</p><span>{{this.currentDayPrice}}</span>
          </div>
        </div>
      </div>
    </div> 

    <div class="main-col">
      <!-- Charts -->
      <div class="charts">
         <trading-vue :data="chartsDc"
          titleTxt=""
          colorTitle="black"
          ref="chart"
          :color-back="colors.colorBack"
          :color-grid="colors.colorGrid"
          :color-text="colors.colorText"
          :width="chartWidth"
          :height="chartHeight"
          :indexBased="true"
          :overlays="overlays"
          v-if="chartsDc!=null"
          >
        </trading-vue>
      </div>

      <!-- Game Details -->
      <div class="game-details" ref="gameDetailsContainer">
        <!-- First Row -->
        <div class="first-row" v-show="!isMobile">
          <div class="progress" id="countdown">
            <Timer
              :timeLimit="gameTimer"
              :currentTimerLeft="currentGameTimer"
              ref="timer"
            />
          </div>
          <div class="current-day-information">
            <div class="info">
              <p>DAY</p><span>{{this.gameSession.current_duration}}</span>
            </div>
            <div class="info">
              <p>PRICE</p><span>{{this.currentDayPrice}}</span>
            </div>
          </div>
        </div> 


        <!-- CTA -->
        <div class="spacerTop20 game-cta" id="CTADetailsAnchor">
          <div :class="[{ctaDisabled: rejoinEndedGame },'start-game','cta']" @click="ctaClick('start')">
            <img :src="this.checkButtonState('start')" alt="icon">
            <h4 :class="{active: buttonState.startActive}">Start</h4>
          </div>
          <div :class="[{ctaDisabled: rejoinEndedGame },'pause-game','cta']" @click="ctaClick('pause')">
            <img :src="this.checkButtonState('pause')" alt="icon">
            <h4 :class="{active: buttonState.pauseActive}">Pause</h4></div> 
          <div :class="[{ctaDisabled: rejoinEndedGame },'restart-game','cta']" @click="ctaClick('reset')">
            <img :src="reset" alt="icon">
            <h4>Reset</h4>
          </div>
        </div>

        <!-- Account Info -->
        <div class="account-info spacerTop20" v-if="latestMarketDataPoint" id="accountInfoAnchor">
          <TableRow :tableDetails="[{title: 'Change',value: latestMarketDataPoint.change.toFixed(2)}]" :isColorCoded="true" :isNumeric="true"/>
          <TableRow :tableDetails="[{title: 'Change (%) ',value: latestMarketDataPoint.change_percent}]" :isColorCoded="true" :isNumeric="true" :isPercentage="true"/>
          <TableRow :tableDetails="[{title: 'Open',value: latestMarketDataPoint.open}]" :isNumeric="true"/>
          <TableRow :tableDetails="[{title: 'High',value: latestMarketDataPoint.high}]" :isNumeric="true"/>
          <TableRow :tableDetails="[{title: 'Low',value: latestMarketDataPoint.low}]" :isNumeric="true"/>
          <TableRow :tableDetails="[{title: 'Close',value: latestMarketDataPoint.close}]" :isNumeric="true"/>
        </div>
      </div>
    </div>

    <!-- Ranking -->
    <div class="ranking spacerTop20" v-if="!isMobile">
      <div class="ranking-header">
        <h3 >Ranking</h3>
        <span class="toggleButton" @click="toggleUserLeaderboardModal" v-if="userRole === 'admin' || userRole === 'trainer'">
          <img src="../../../../../assets/icons/leaderboard_dark.svg" alt="leaderboard">
        </span> 
      </div>
      <TableColumn :propData="leaderboardList" :header="leaderboardHeader" class="spacerTop10" :columnTemplateCount="columnTemplateCount" gridSize="7"/>
    </div>



    <div class="ranking-mobile spacerTop20" v-if="isMobile" id="rankingDetailsAnchor">
      <div class="ranking-header">
        <h3 >Ranking</h3>
        <span class="toggleButton" @click="toggleUserLeaderboardModal" v-if="userRole === 'admin' || userRole == 'trainer'">
          <img src="../../../../../assets/icons/leaderboard_dark.svg" alt="leaderboard">
          </span> 
        </div>
      <TableColumn :propData="leaderboardList" :header="leaderboardHeader" class="spacerTop10" :columnTemplateCount="columnTemplateCount" gridSize="7"/>
    </div>

  </div>
</template>

<script>
//Charts
import {TradingVue, DataCube } from 'trading-vue-js'
import Overlays from 'tvjs-overlays'

//Pusher Js
import Pusher from 'pusher-js';

//Components
import TableRow from '@/components/TableRow';
import TableColumn from '@/components/TableColumn';
import Timer from '../components/Timer';
import LeaderboardModal from '../components/LeaderboardModal';
import { Portal, PortalTarget } from 'portal-vue';

//API
import { gameShow, gameStart, gamePause, gameResume, gameReset, marketDataPoints, latestMarketDataPoints, gameShowLeaderboard } from '@/services/adminAPI/game.js'
import { getLeaderboard } from '@/services/api/leaderboard.js'

//Button State Icon
import startActive from '../../../../../assets/icons/start_active.png';
import startInactive from '../../../../../assets/icons/start_inactive.png';
import pauseActive from '../../../../../assets/icons/pause_active.png';
import pauseInactive from '../../../../../assets/icons/pause_inactive.png';
import reset from '../../../../../assets/icons/reset.png';

import Swal from 'sweetalert2'
import moment from 'moment';
import {eventBus} from '@/main.js';

export default {
  components:{
    TableRow,
    TableColumn,
    Timer,
    LeaderboardModal,
    Portal,
    PortalTarget,
    TradingVue
  },
  beforeRouteLeave(to, from, next){
    if(!this.gameEnded && !this.rejoinEndedGame){
      this.$swal({
        title: 'Are you sure to leave the game?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      })
      return;
    }
    next();
  },
  data(){
    return{
      columnTemplateCount: "50px minmax(100px,1fr) 120px minmax(130px,1.5fr) minmax(150px,1.5fr) minmax(130px,1.5fr) minmax(150px,1.1fr)",
      startActive,
      startInactive,
      pauseActive,
      pauseInactive,
      reset,
      buttonState:{
        startActive: false,
        pauseActive: false,
      },
      pusherInstance: null,
      pusherChannel: null,
      overlays: [Overlays['MACD'],Overlays['RSI']],
      chartsDc: null,
      chartWidth: null,
      chartHeight: null,
      gameSession: '',
      currentSessionId: '',
      currentDayPrice: '',
      gameEnded: false,
      gameStarted: false,
      gamePaused: false,
      marketDataPoints: [],
      orderQuantity: 0,
      leaderboardList: [],
      gameTimer: 0,
      currentGameTimer: null,
      isAPICalling: false,
      isForcedQuit: false,
      leaderboardHeader:['No','Name','Net Position','Total Accu. Profit/Loss','Net Equity','Margin Excess(Call)','Equity/Margin (%)'],
      latestMarketDataPoint: undefined,
      isInit: true,
      showModal: false,
      currentTab: 0,
      isMobile: false,
      colors: {
        colorBack: '#fff',
        colorGrid: '#eee',
        colorText: '#333',
      },
      leaderboardDetails: null,
      rejoinEndedGame: false,
    }
  },
  async created(){

    window.addEventListener('beforeunload', this.handlePageLeave)

    //Start loader
    eventBus.$emit('loader',true);

    //Assign Data
    this.currentDayPrice = this.computedCurrentDayPrice;

    try {

      //Show game data
      const gameShowAPI = await gameShow(this.$route.params.gameId);
      this.gameSession = gameShowAPI.data.response.game;

      //Game Timer
      this.gameTimer = this.gameSession.timer;
      if(this.gameTimer != this.gameSession.current_timer_left){
        this.currentGameTimer = this.gameSession.current_timer_left;
      }

      if(this.gameSession.status === 'ONGOING'){
        this.gameStarted = true;
        this.buttonState.startActive = true;
        this.isInit = false;
        this.$refs.timer.startTimer();
        this.$refs.mobileTimer.startTimer();
      }else if (this.gameSession.status === 'PAUSED'){
        this.buttonState.pauseActive = true;
        this.gamePaused = true;
      }else if(this.gameSession.status === 'ENDED'){
        this.rejoinEndedGame = true;
      }

      //Initialize Pusher
      this.pusherInstance = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
        cluster: 'ap1'
      });

      //Subscribe Pusher Channel
      this.pusherChannel = this.pusherInstance.subscribe(`game-${this.$route.params.gameId}`)

      this.pusherChannel.bind('game-leaderboard',this.gameLeaderboardCallback);
      this.pusherChannel.bind('game-show-leaderboard',this.gameShowLeaderboardCallback);

      if(!this.rejoinEndedGame){
        //Bind Channels
        this.pusherChannel.bind('game-start',this.gameStartCallback);
        this.pusherChannel.bind('game-next-day',this.gameNextDayCallback);
        this.pusherChannel.bind('game-update-game-session',this.gameUpdateSessionCallback);
        this.pusherChannel.bind('game-ended',this.gameEndCallback);
        this.pusherChannel.bind('game-pause',this.gamePauseCallback);
        this.pusherChannel.bind('game-resume',this.gameResumeCallback);
        this.pusherChannel.bind('game-reset',this.gameResetCallback);
      }      
      // //Get market data points
      const marketDataPointAPI = await marketDataPoints(this.gameSession.id);
      this.marketDataPoints = marketDataPointAPI.data.response.marketDataPoints;

      //Get Latest market data points
      const latestMarketDataPointsAPI = await latestMarketDataPoints(this.$route.params.gameId);
      this.latestMarketDataPoint = latestMarketDataPointsAPI.data.response.latestMarketDataPoint;

      // //Initialize the charts
      this.initializeCharts();


      const getLeaderboardAPI = await getLeaderboard({game_id : this.$route.params.gameId,limit: 100,page: 1});

      this.leaderboardList = getLeaderboardAPI.data.response.leaderboards;
      

    } catch (error) {
      console.log(error);
      eventBus.$emit('loader',false);
      this.$swal({
        icon: 'error',
        title: 'Oops!',
        text: error.response.data.userMessage
      }).then(() => {
        this.gameEnded = true;
        this.isForcedQuit = true;
        this.$router.go(-1);
      });
    }

    eventBus.$emit('loader',false);

  },
  mounted(){
    this.watchResize();
    window.addEventListener('resize',this.watchResize);
  },
  methods:{
    //Pusher Callback Methods
    gameStartCallback(){
      this.gameStarted = true;
      this.isInit = false;
      this.$refs.timer.startTimer();
      this.$refs.mobileTimer.startTimer();
    },
    gameNextDayCallback(){
      this.currentGameTimer = null;
      this.$refs.timer.startTimer();
      this.$refs.mobileTimer.startTimer();
    },
    gameLeaderboardCallback(response){

      this.leaderboardDetails = response.data.leaderboard;

      const params = {
        game_id : this.$route.params.gameId,
        limit: 100,
        page: 1,
      }

      getLeaderboard(params).then((response) => {
        this.leaderboardList = response.data.response.leaderboards;
      }).catch((error) => {
        console.log(error);
      });
    },
    gameShowLeaderboardCallback(response){
      this.showModal = true;
      this.leaderboardDetails = response.data.leaderboard;
    },
    async gameUpdateSessionCallback(response){
      await this.updateGameDetails(response);
    },
    gameEndCallback(response){
      this.updateGameDetails(response);
      this.gameEnded = true;
      this.gameStarted = false;
      this.$refs.timer.shutdownTimer();
      this.$refs.mobileTimer.shutdownTimer();
      this.buttonState.startActive = false;
      this.buttonState.pauseActive = false;
      window.removeEventListener('beforeunload',this.handlePageLeave);
        if(!this.isForcedQuit){
          this.$swal({
            icon: 'success',
            title: 'Your current game has ended',
            text: 'You may review the game on this screen or in your profile.'
          });
        }
    },
    gamePauseCallback(){
      this.$refs.timer.toggleTimer(false);
      this.$refs.mobileTimer.toggleTimer(false);
      this.gamePaused = true;
      this.gameStarted = false;
    },
    gameResumeCallback(){
       if(this.isInit){
        this.$refs.timer.startTimer();
        this.$refs.mobileTimer.startTimer();
      }else{
        this.$refs.timer.toggleTimer(true);
        this.$refs.mobileTimer.toggleTimer(true);
      }

      this.isInit = false;
      this.gameStarted = true;
      this.gamePaused = false;
    },
    async gameResetCallback(){
      this.gameStarted = true;
      this.gamePaused = false;
      eventBus.$emit('loader',true);

      try {
        this.gameTimer = this.gameSession.timer;
        this.currentGameTimer = null;
        this.$refs.timer.resetTimer();
        this.$refs.mobileTimer.resetTimer();

        eventBus.$emit('loader',false);

      } catch (error) {
        eventBus.$emit('loader',false);
      }

    },

    //Vue Methods
    handlePageLeave(){
      const event = window.event;

      // For IE and Firefox prior to version 4
      if (event) {
        event.returnValue = 'Sure?';
      }
      return 'Sure?';
    },
    initializeCharts(){
      this.chartsDc = null
      const dataInitalization = {
        chart:{
          type: "Candles",
          data: this.formattedJsonData,
          tf: '1D'
        },
        offchart: [
         {
          name: "RSI",
          type: "RSI",
          data: []
         },
         {
          name: "MACD",
          type: "MACD",
          data: [],
          settings: {
              "histColors": [
                  "#35a776", "#79e0b3", "#e54150", "#ea969e"
              ]
          }
          }
        ]
      }
      this.$set(this, 'chartsDc', new DataCube(dataInitalization))

      setTimeout(() => {
        this.chartWidth = document.querySelector('.charts').clientWidth;
        this.chartHeight = document.querySelector('.charts').clientHeight;

        const firstData = this.formattedJsonData[0][0];
        let lastData;
        if(this.formattedJsonData.length > 151){
          lastData = this.formattedJsonData[150][0];
        }else{
          lastData = this.formattedJsonData[this.formattedJsonData.length - 10][0]
        }

        this.$refs.chart.setRange(firstData,lastData)
        this.$refs.chart.goto(this.marketDataPoints.length)
      }, 100);
    },
    updateQuantity(quantity){
      this.orderQuantity = quantity;
    },
    checkButtonState(type){
      switch (type) {
        case 'start':
          return this.buttonState.startActive ? this.startActive : this.startInactive;
        case 'pause':
          return this.buttonState.pauseActive ? this.pauseActive : this.pauseInactive
        default:
          return null
      }
    },
    ctaClick(type){
      if(!this.gameEnded && !this.rejoinEndedGame){
        if(type == 'start' && !this.buttonState.startActive && (this.gamePaused || !this.gameStarted)){

          if(this.buttonState.pauseActive){
            this.resumeGame();
          }else{
            this.startGame();
          }

          this.buttonState.startActive = true;
          this.buttonState.pauseActive = false
        

        }else if(type == 'pause' && !this.buttonState.pauseActive && !this.gamePaused){
          this.buttonState.pauseActive = true;
          this.buttonState.startActive = false;
          this.pauseGame();

        }else if(type == 'reset' && this.gamePaused){

          this.resetGame();
        }
      }
    },
    startGame(){
      gameStart(this.$route.params.gameId).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });
    },
    pauseGame(){
      gamePause(this.$route.params.gameId).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });
    },
    resumeGame(){
      gameResume(this.$route.params.gameId).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });
    },
    resetGame(){
      this.$swal({
        title: 'Are you sure to reset the game?',
        text: 'All players would loss progress',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          gameReset(this.$route.params.gameId).then((response) => {
            this.buttonState.pauseActive = false;
            this.buttonState.startActive = true;
            console.log(response);
          }).catch((error) => {
            console.log(error);
          });
        }
      })
    },
    onModalClose(data){
      this.showModal = data;
    },
    async toggleUserLeaderboardModal(){
      await gameShowLeaderboard(this.$route.params.gameId).then((response) => {
      }).catch((error) => {
        this.$swal({
          icon: 'error',
          title: 'Oops',
          text: error.response.data.userMessage
        });
      });
    },
    watchResize(){
      window.innerWidth <= 600 ? this.isMobile = true : this.isMobile = false
      this.chartWidth = document.querySelector('.charts').clientWidth;
      this.chartHeight = document.querySelector('.charts').clientHeight;
    },
    returnLatestFormattedDataPoint(latestData){
      return[
        Number(moment(latestData.date).format("x")), 
        Number(latestData.open),
        Number(latestData.high),
        Number(latestData.low),
        Number(latestData.close),
        Number(latestData.volume)
      ]
    },
    async updateGameDetails(response){

      try {
        this.isAPICalling = true;
        this.gameSession.current_duration = response.data.current_duration;

        //Update Graph
        const latestDataPoint = await response.data.latestMarketDataPoint
        this.latestMarketDataPoint = latestDataPoint;

        this.marketDataPoints.push(latestDataPoint);

        this.chartsDc.update({candle: this.returnLatestFormattedDataPoint(latestDataPoint)});
        setTimeout(() => {
          this.$refs.chart.goto(this.marketDataPoints.length)
        }, 500);

        this.isAPICalling = false
      }catch(error){
        this.isAPICalling = false
      }
    },
  },
  computed:{
    formattedJsonData(){
      return this.marketDataPoints.map(function(data){
        return [
          Number(moment(data.date).format("x")), 
          Number(data.open),
          Number(data.high),
          Number(data.low),
          Number(data.close),
          Number(data.volume)
        ]
      })
    },
    computedCurrentDayPrice(){
      const lastItem = this.marketDataPoints.slice(-1)[0];
      if(lastItem == undefined)
        return ''

      return lastItem.close; 

    },
  },
  watch:{
    computedCurrentDayPrice(newData){
      this.currentDayPrice = newData;
    },
    gameEnded(newValue){
      if(newValue){
        window.removeEventListener('beforeunload',this.handlePageLeave);
        if(!this.isForcedQuit){
          this.$swal({
            icon: 'success',
            title: 'Your current game has ended',
            text: 'You may review the game on this screen or in your profile.'
          });
        }
      }
    },
  },
  async beforeDestroy(){
    window.removeEventListener('beforeunload', this.handlePageLeave);
    window.removeEventListener('resize', this.watchResize);
    this.pusherInstance.unsubscribe(`game-${this.$route.params.gameId}`)
    this.pusherChannel.unbind()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .trading-vue-botbar{
  height: 1px;
  display: block;
  border-top: 1px solid rgb(51, 51, 51);
}

/deep/ #trading-vue-js-botbar-canvas{
  display: none;
}

.ranking-header{
  display: flex;
  align-items: center;

  .toggleButton{
    margin-left: 10px;
    cursor: pointer;
  }
}

.first-row{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;


  .current-day-information{
    font-weight: 700;
    font-size: 18px;

    @media screen and (max-width:1280px){ 
      font-size: 13px;
    }

    p{
      display: inline-block;
      width: 60px;

      @media screen and (max-width:1280px){ 
        width: 40px;
      }
    }

    span{
      color: $red;
      margin-left: 10px;
    }
  }
}

.first-row-mobile{
  align-items: center;
  background: white;
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 10;

  .tabs{
    display: flex;
    width: 100%;
    

    .tab-item{
      flex: 1 1;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px;
      display: grid;
      place-items: center;
      background: $grayDark;
      color: white;
      cursor: pointer;

      &.active{
        background: $red;
      }
    }
  }

  .day-info{
    display: flex;
    align-items: center;
    margin: 15px 0;
    margin-left: 15px;

    .current-day-information{
      font-weight: 700;
      font-size: 18px;
      margin-left: 20px;

      @media screen and (max-width:1280px){ 
        font-size: 13px;
      }

      p{
        display: inline-block;
        width: 60px;

        @media screen and (max-width:1280px){ 
          width: 40px;
        }
      }

      span{
        color: $red;
        margin-left: 10px;
      }
    }
  }
}

.quantity-row{
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;

  .quantity-title{
    font-weight: 700;
    font-size: 18px;
    margin-right: 20px;
  }

  .quantity-input{
    padding: 10px;
    border: 1px solid var(--gray414241);
  }
}

.row-title{
  padding: 6px;
  background: var(--gray414241);
  border-radius: 10px;
  display: block;
  color: white;

  @media screen and (max-width:1280px){
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;
  }
}


.game-grid{
  // display: flex;
  width: calc(100vw - 280px);
  position: relative;


  @media screen and (max-width:768px){
    width: calc(100vw - 60px);
  }

  @media screen and (max-width:600px){
    width: calc(100vw - 30px);
    flex-direction: column;
  }

  @media screen and (max-width:450px){
    width: 100vw;
  }
}

.main-col{
  display: flex;
  // flex-grow: 1;
  // width: 55%;  

  @media screen and (max-width:600px){
     flex-direction: column;
  }
}

.ranking-mobile{
  margin: 0 1rem 1rem;

}

.charts{
  width: 55%;
  flex-grow: 1;
  height: 400px;
  position: relative;
  z-index: 0;

  @media screen and (max-width:600px){
     width: 100%;
  }
}

#chartContainer{
  width: 100%;
  height: 100%;
}


.legend{
  position: absolute;
	left: 12px;
	top: 12px;
	z-index: 1;
	font-size: 15px;
  color: black;
	line-height: 18px;
	font-weight: 300;
}

.game-details{
  // width: 100%;
  max-width: 500px;
  padding-left: 15px;

  @media screen and (max-width:600px){
    padding-left: 0;
    margin: 1rem;
  }
}

  .ctaDisabled{
    cursor: not-allowed !important;
  }

.game-cta{
  display: flex;
  flex-wrap: wrap;


  .cta{
    text-align: center;
    flex: 1 1 45%;
    padding: 1rem 0;
    margin: 5px;
    background: #F0F0F0;
    border-radius: 7px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
    transition: .4s;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:1280px){
      padding: .5rem 0;
      font-size: 13px;
    }

    img{
      margin-right: 10px;
      height: 15px;
      width: 15px;

       @media screen and (max-width:1280px){
        height: 10px;
        width: 10px;
      }
    }

    h4.active{
      color: $green;
    }


    &:hover{
      transition: .4s;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
    }

    &:active{
      transition: .2s;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    }

  }
}

.progress {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}


.actions-tab{
  display: flex;
  flex-wrap: wrap;

  .buy-action{
    background-color: var(--green009F00);
    margin-right: 10px;
  }

  .sell-action{
    background-color: #C60000;
  }
}

.action{
  padding: 10px 30px;
  color: white;
  cursor: pointer;
  border-radius: 2px;
  font-size: 17px;
  border: none;
  flex: 0 0 100px;

  @media screen and (max-width:1280px){
    flex: 1 1 100px;
    font-size: 13px;
  }
}

button:disabled,
button[disabled]{
  opacity: 0.5;
  cursor: not-allowed;
}


/deep/ .table {
  border: none;

  .table-options-row{

    & > *{
      padding: 0rem;
    }


    .table-title{
      border-radius: 2px;
      padding: 2px;
      background: none;
      
      h3{
        color: var(--gray414241);
      }
    }

    .table-description{
      background-color: white;
    }
  }
}

/deep/ .table-body-wrapper{
  max-height: 100px;

  @media screen and (max-width:768px) {
    max-height: 80px;

  }
}


.spacerTop20{
  margin-top: 20px
}

.spacerTop10{
  margin-top: 10px
}

/deep/ .legend{
  position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	font-size: 15px;
  color: black;
	line-height: 18px;
	font-weight: 300;
}

#countdown{
  /deep/ #wrapper{
    text-align: center;
  }
}
</style>