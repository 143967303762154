<template>
  <div class="modal-container" v-if="showModal" @click="onClose">
    <div class="modal">
      <h2 class="modal-header">Leaderboard</h2>
      <div class="modal-content">
        <table>
          <thead>
            <tr>
              <th class="row-1">Ranking</th>
              <th class="row-1">Display Name</th>
              <th class="row-1">ROI</th>
            </tr>
          </thead>
          <tbody v-if="parsedLeaderboardData != null">
            <tr v-for="data in parsedLeaderboardData" :key="data.user_id">
              <td :class="{ isUser: data.isCurrentUser }">
                {{ data.ranking }}
              </td>
              <td :class="{ isUser: data.isCurrentUser }">
                {{ data.name || `Player ${data.ranking}` }}
              </td>
              <td
                :class="{ isUser: data.isCurrentUser }"
                :style="{
                  color: parseFloat(data.roi) >= 0.0 ? '#009F00' : '#C60000',
                }"
              >
                {{ data.roi }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getLeaderboard } from "@/services/api/leaderboard.js";

export default {
  props: {
    gameId: {
      required: true,
    },
    isShown: {
      required: false,
      default: false,
    },
    gameDetails: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      leaderboardData: null,
      showModal: false,
    };
  },
  async created() {
    this.showModal = this.isShown;

    this.leaderboardData = this.gameDetails;
  },
  methods: {
    getLeaderboardData() {
      const params = {
        game_id: this.gameId,
        limit: 10,
      };

      getLeaderboard(params).then((leaderboardAPI) => {
        if (leaderboardAPI.data.response.leaderboards.length > 0) {
          this.leaderboardData = leaderboardAPI.data.response.leaderboards;
        }
      });
    },
    onClose(event) {
      if (event.target.classList.contains("modal-container")) {
        this.showModal = false;
        this.$emit("closeModal", false);
      }
    },
  },
  computed: {
    getUserProfile() {
      return this.$store.getters.getUserProfile;
    },
    parsedLeaderboardData() {
      const userPosition = this.leaderboardData
        .map((data) => data.user_id)
        .indexOf(this.getUserProfile.id);

      let returnedLeaderboard = [];

      if (userPosition > 10) {
        returnedLeaderboard = this.leaderboardData
          .slice(0, 9)
          .map(function(data, index) {
            return {
              ranking: index + 1,
              name: data.user.display_name,
              roi: data.roi,
              isCurrentUser: false,
            };
          });

        returnedLeaderboard.push({
          ranking: userPosition + 1,
          name: this.leaderboardData[userPosition].user.display_name,
          roi: this.leaderboardData[userPosition].roi,
          isCurrentUser: true,
        });
      } else {
        const vm = this;
        returnedLeaderboard = this.leaderboardData
          .slice(0, 10)
          .map(function(data, index) {
            return {
              ranking: index + 1,
              name: data.user.display_name,
              roi: data.roi,
              isCurrentUser: data.user_id == vm.getUserProfile.id,
            };
          });
      }
      console.log(returnedLeaderboard);
      return returnedLeaderboard;
    },
  },
  watch: {
    isShown(show) {
      this.showModal = show;
    },
    gameDetails(data) {
      this.leaderboardData = data;
      this.parsedLeaderboardData;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999999;
  display: grid;
  place-items: center;

  .modal {
    width: 60%;
    max-width: 1200px;
    margin: auto;
    max-height: 80%;
    background: #f0f0f0;
    box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.3);
    border-radius: 20px;
    padding: 20px;

    .modal-content {
      margin-top: 10px;
      width: 100%;
      overflow: auto;
      background: white;
    }

    @media screen and (max-width: 768px) {
      left: 0;
      width: 90%;
      max-width: initial;
      padding: 10px;
    }
  }
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  text-align: left;
  table-layout: fixed;
  white-space: nowrap;
  width: 100%;

  tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 500px;
    max-height: 63vh;
  }

  tr {
    display: flex;
  }

  th {
    background-color: #414241;
    // text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding: $twelve 2rem;
    font-size: $fourteen;
    color: white;
    flex: 1 1 100%;

    @media screen and (max-width: 768px) {
      padding: 10px;
    }

    span {
      float: right;
    }
  }

  td {
    color: black;
    background-color: white;
    padding: 1rem 2rem;
    flex: 1 1 100%;
  }

  .isUser {
    background: var(--redC41230) !important;
    color: white !important;
  }
}
</style>
